<template>
  <div>
    <b-table
      :items="sectionEvaluations"
      :fields="fields"
      responsive
      sort-by="start_date"
    >
      <template v-if="sectionEvaluations.length == 0" #table-caption
        >No tiene evaluaciones planificadas.</template
      >
      <template #cell(actions)="data">
        <b-dropdown size="sm" variant="secondary">
          <b-dropdown-item @click="$emit('selected', data.item.id)"
            >Instrumento</b-dropdown-item
          >
          <b-dropdown-item @click="$emit('selectedInstrument', data.item.id)"
            >Notas</b-dropdown-item
          >
        </b-dropdown>
      </template>
      <template #cell(study_unit)="data">
        {{ getEvaluationStudyUnit(data.value) }}
      </template>
      <template #cell(type)="data">
        {{ getEvaluationType(data.value) }}
      </template>
      <template #cell(evaluation_instrument)="data">
        {{ getEvaluationInstrumentType(data.value) }}
      </template>
      <template #cell(start_date)="data">
        {{ data.value | toDateTime }}
      </template>
      <template #cell(end_date)="data">
        {{ data.value | toDateTime }}
      </template>
      <template #cell(weighing)="data"> {{ data.value }}% </template>
    </b-table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Evaluations",
  components: {},
  props: {
    Section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nombre", sortable: true },
        { key: "study_unit", label: "Unidad", sortable: true },
        { key: "start_date", label: "Inicio", sortable: true },
        { key: "end_date", label: "Término", sortable: true },
        { key: "type", label: "Tipo", sortable: true },
        { key: "evaluation_instrument", label: "Instrumento", sortable: true },
        { key: "weighing", label: "Ponderación", sortable: true },
        { key: "actions", label: "-" },
      ],
      evaluation_types: [
        { id: 1, value: "Diagnóstica" },
        { id: 2, value: "Formativa" },
        { id: 3, value: "Sumativa" },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      instrumentTypes: names.EVALUATION_INSTRUMENTS,
      studyUnits: names.STUDY_UNITS,
    }),
    sectionEvaluations() {
      return this.evaluations.filter((x) => x.section == this.Section.id);
    },
  },
  methods: {
    fetchEvaluationTypes() {
      this.$restful.Get(`/teaching/evaluation-types/`).then((response) => {
        this.evaluation_types = response;
      });
    },
    getEvaluationType(id) {
      const type = this.evaluation_types.find((x) => x.id == id);
      if (type) return type.value;
      return "-";
    },
    getEvaluationStudyUnit(id) {
      const study_unit = this.studyUnits.find((x) => x.id == id);
      if (study_unit) return study_unit.name;
      return "-";
    },
    getEvaluationInstrumentType(id) {
      const type = this.instrumentTypes.find((x) => x.id == id);
      if (type) return type.value;
      return "-";
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EVALUATIONS_BY_SECTION, this.Section.id)
      .then(() => {
        this.loading = true;
      });
  },
};
</script>

<style>
</style>